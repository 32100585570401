.navbar {
  background-image: url("./components/image/header.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: 0px 4px 8px rgba(128, 128, 128, 0.453);
  height: fit-content;
  position: sticky !important;
  z-index: 2;
  width: 100% !important;
  top: 0px;
}

.navbar-brand {
  color: white !important;
  margin-right: 2rem;
}

.left-nav {
  width: 725px;
  justify-content: space-evenly;
}

.right-nav {
  position: relative;
  top: 8px;
}

.navbar-collapse {
  .navbar-nav {
    .nav-link {
      color: white !important;
      font-size: 1.2em;
      font-weight: bold;
    }
    .nav-link:hover {
      text-decoration: underline;
    }
    .nav-link:active {
      text-decoration: underline;
    }
  }
}

.navbar-brand {
  padding-left: 5px;
  h1 {
    margin-top: 3px;
    margin-left: 5px;
  }
}
.navbar-toggler {
  background: white !important;
  padding: 4px !important;
  border-radius: 80px !important;
}

.logo {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}

.dropdown-item:active {
  background-color: rgb(100, 54, 54) !important;
}

.dropdown-menu {
  border-radius: 3px !important ;
}

.carousel-item {
  width: 100vw !important;
  height: 30% !important;
}

.carousel-caption > h3 {
  font-size: 4vw !important;
  font-weight: bold;
  text-shadow: 1px 2px 5px black;
}

.carousel {
  height: 40% !important;
}

.carousel-caption > p {
  font-size: 2.5vw !important;
  text-shadow: 1px 1px 8px black;
}

.marque {
  background: linear-gradient(
    rgba(104, 60, 60, 0.621),
    rgb(56, 54, 54),
    rgb(96, 53, 53)
  );
  position: relative;
  bottom: 10px;
  height: 25px;
  margin: 1rem 0;
  .lists {
    list-style-type: none;
    display: flex;
    justify-content: space-around !important;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    text-shadow: 1px 2px 4px black;
    position: relative;
    li {
      margin-right: 15rem;
    }
  }
}
.logo-box {
  align-items: center;
}
.info-logo {
  border-radius: 100%;
  box-shadow: 0px 10px 20px rgb(6 6 6 / 67%);
  transform: perspective(800px) rotateX(20deg);
  width: 25vw;
  height: 25vw;
}

.carousel-indicators > button {
  display: none;
}

.head {
  font-size: 2.3rem;
  font-weight: bold;
}

.para {
  line-height: 1.8;
  font-size: 1.4rem;
  color: black;
  padding-left: 30px;
}

.list-para {
  padding-left: 30px !important;
}
.para-list {
  line-height: 1.8;
  font-size: 1.4rem;
}

.history-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
section {
  padding: 60px 0;
}
section .section-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}
#footer {
  background-image: url("./components/image/header.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#footer h3 {
  padding-left: 10px;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color: #ffffff;
}
#footer a {
  color: #ffffff;
  text-decoration: none !important;
  background-color: transparent;
}

#footer ul.social {
  display: flex;
  justify-content: center;
}

#footer ul.social li {
  padding: 3px 0;
}
#footer ul.social li a i {
  margin-right: 5px;
  font-size: 25px;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#footer ul.social li:hover a i {
  font-size: 30px;
  margin-top: -10px;
}
#footer ul.social li a {
  margin-right: 2rem;
  color: #ffffff;
}
#footer ul.social li a:hover {
  color: #eeeeee;
}
#footer ul.quick-links li a {
  color: #ffffff;
  line-height: 2;
}
#footer ul.quick-links li {
  padding: 3px 0;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#footer ul.quick-links li:hover {
  padding: 3px 0;
  margin-left: 5px;
  font-weight: 700;
}
#footer ul.quick-links li a i {
  margin-right: 5px;
}
#footer ul.quick-links li:hover a i {
  font-weight: 700;
}
@media (max-width: 767px) {
  #footer h3 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
}
h2 {
  padding: 25px 0;
  text-align: center;
  color: #fff;
  background: #7c8490;
}

#navigation ul.social li {
  padding: 3px 0;
}
#navigation ul.social li a i {
  // margin-right: 5px;
  font-size: 25px;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#navigation ul.social li:hover a i {
  font-size: 30px;
  margin-top: -10px;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#navigation ul.social li a {
  color: #ffffff;
}
#navigation ul.social li a:hover {
  color: #eeeeee;
}
#navigation ul.quick-links li a {
  color: #ffffff;
  line-height: 2;
}

#navigation {
  .navbar-nav {
    .icons-row {
      width: 150px;
      .social {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.logoft {
  text-shadow: 1px 3px 4px black;
  width: 6rem;
  border-radius: 500px;
  margin: 0px 15px;
}

.logoft:hover {
  box-shadow: 1px 3px 6px black;
}
.logo-name {
  color: rgb(244, 243, 243);
  text-shadow: 2px 3px 10px rgb(9, 9, 9);
  font-size: 1.5rem;
  position: relative;
  bottom: 6px;
}

.address h4,
p {
  color: white;
}

.address > div {
  position: relative;
  bottom: 15px;
}

.reg-list {
  text-align: center;
}

.vision-logo-name {
  color: black;
  text-shadow: 1px 1px 3px rgb(181 166 166 / 84%);
  font-size: 3.5rem;
  position: relative;
  bottom: 16px;
  left: 1px;
  font-weight: bold;
}

.vision-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logoft {
    width: 15rem;
  }
}

.box {
  width: 90vw;
  height: fit-content;
  padding: 2rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  border-radius: 5px;
  box-shadow: 0px 0px 8px #46424270;
  margin: 3rem auto;
  background-color: rgb(70 44 44 / 9%);

  .head {
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
  }
  .vision-icon {
    width: 2rem;
    border-radius: 50%;
    margin-right: 10px;
  }
  .para {
    padding-left: 2rem;
    padding-top: 1rem;
  }
}
.box1 {
  width: 90vw;
  height: fit-content;
  padding: 2rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: rgb(70 44 44 / 9%);
  box-shadow: 0px 0px 8px #46424270;
  margin: 3rem auto;

  .head {
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
  }
  .vision-icon {
    width: 2rem;
    margin-right: 10px;
    border-radius: 50%;
  }
  .para {
    padding-left: 2rem;
    padding-top: 1rem;
  }
}
.cards {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.img {
  margin-bottom: 1rem;
}

.carousel-box {
  padding: 20px auto !important;
  background-color: rgb(70 44 44 / 9%);
  box-shadow: 0px 0px 8px #46424270;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 450px;
  margin: 1rem auto;

  .carousel-img {
    max-height: 380px;
    min-width: 250px;
    border-radius: 5px;
  }
}

.carousel-head {
  font-size: 3rem !important;
  margin-bottom: 1rem;
  text-shadow: 0px 0px 8px gray;
}
.contact-container {
  padding: 1rem;
  .contact {
    min-height: 80vh;
    display: flex;
    align-items: center;

    .contact-logo {
      max-height: 70vh !important;
      opacity: 0.8;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .contact-info {
      min-height: 70vh;

      h2 {
        color: #fff;
        background: #54333c;
        text-align: left;
        border-top-left-radius: 50px;
        padding: 5px 20px;
        border-bottom-right-radius: 50px;
      }
      .address-info {
        margin: 0;
        margin-left: 1rem;
      }
      h1 {
        margin-left: 0.5rem;
      }
      .email {
        margin-bottom: 4px;
      }
      .contact-no {
        margin-bottom: 4px;
        margin-top: 0px;
      }
    }
  }
}

.shimmer {
  width: 98%;
  height: 30vw;
  background-color: rgba(56, 56, 58, 0.37);
  margin: auto;
  border-radius: 3px;
  opacity: 0.8;
  position: relative;
  overflow: hidden;
}
@keyframes shimmerAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.shimmer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  animation: shimmerAnimation 1s infinite;
}

.info-logo-1 {
  border-radius: 50%;
  width: 28vw;
  border-radius: 100%;
  box-shadow: 0px 10px 20px rgb(6 6 6 / 67%);
  /* Adds a drop shadow */
  transform: perspective(800px) rotateX(20deg);
  opacity: 0.7;
  margin-bottom: 1.5rem;
}

.carousel-head-1 {
  font-size: 2.5rem !important;
  margin-bottom: 1rem;
  text-shadow: 0px 0px 8px gray;
}
